/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #eee !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.login-loader svg {
  width: 22px !important;
  height: 22px !important;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.26);
}
.mt-0 {
  margin-top: 0px !important;
}
.mr-1 {
  margin-right: 2px !important;
}
.mr-2 {
  margin-right: 5px !important;
}
.mr-3 {
  margin-right: 10px !important;
}
.mr-4 {
  margin-right: 15px !important;
}
.ml-1 {
  margin-left: 2px !important;
}
.ml-2 {
  margin-left: 5px !important;
}
.ml-3 {
  margin-left: 10px !important;
}
.ml-4 {
  margin-left: 15px !important;
}
.mt-1 {
  margin-top: 2px !important;
}
.mt-2 {
  margin-top: 5px !important;
}
.mt-3 {
  margin-top: 10px !important;
}
.mt-4 {
  margin-top: 15px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 2px !important;
}
.mb-2 {
  margin-bottom: 5px !important;
}
.mb-3 {
  margin-bottom: 10px !important;
}
.col-6 {
  width: 49.4%;
}
.col-8 {
  width: 79% !important;
}
.col-4 {
  width: 32.7% !important;
}
.col-7 {
  width: 69% !important;
}
.col-5 {
  width: 29% !important;
}
.col-12 {
  width: 100%;
}
.position-relative {
  position: relative;
}
.d-inline-block {
  display: inline-block;
}
.border-right {
  display: inline-block;
  border-right: 1px solid #978f8f;
  height: 35px;
  position: relative;
  /* top: 10px; */
  margin-right: 2px;
}
.payment-popup-height {
  max-height: 77vh !important;
}
.cdk-overlay-container {
  z-index: 999999 !important;
}
.primary-btn,
.primary-bg-color,
.btn-primary {
  background-color: #5176fb !important;
  color: white !important;
}
.mat-simple-snackbar-action,
.mat-simple-snackbar-action {
  color: #fff;
  display: none !important;
}
.paymentMethod .mat-placeholder-required {
  display: none !important;
}
.fv__ui-tab-nav {
  display: none !important;
}
.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}
.d-flex {
  display: flex;
  align-items: center;
}
.d-block {
  display: block;
}
.adpanel .mat-dialog-container.ng-animating {
  transform: translateY(100%);
  transition: all 1s;
}
.adSnippetimg img {
  width: auto;
  max-height: 300px;
}
.adSnippetimg iframe {
  max-width: 100%;
  max-height: 300px;
}
.mat-table {
  font-family: "source sans pro" !important;
}
.adpanel .mat-dialog-container {
  position: relative;
}

.mat-progress-bar-fill::after {
  background-color: #6f8bf0 !important;
}
.no-overlay.mat-button:hover .mat-button-focus-overlay,
.no-overlay.mat-stroked-button:hover .mat-button-focus-overlay {
  opacity: 0 !important;
}
.no-overlay .mat-button-ripple {
  display: none !important;
}
.no-overlay .mat-button-focus-overlay {
  display: none !important;
}
.fv__ui-nav {
  height: 60px !important;
}
.header-caption {
  display: flex;
  align-items: center;
}
.progress {
  width: 100%;
  display: block;
  font-size: 13px;
  text-align: center;
  margin-bottom: 18px;
}
.disable {
  visibility: hidden;
}
.header-nav-logo {
  margin-left: 15px;
  max-height: 50px;
  display: flex;
  justify-content: center;
}
.logo_icon {
  width: auto;
  max-height: 50px;
  max-width: 200px;
}
.opacity-0 {
  opacity: 0;
}
.payment-popup-height .mat-form-field-required-marker {
  display: none !important;
}
.matspinner {
  position: absolute !important;
  left: 46%;
  width: 25px !important;
  height: 25px !important;
  top: 16%;
}
.matspinner svg {
  width: 25px !important;
  height: 25px !important;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #fff !important;
}
.color-black.mat-progress-spinner circle,
.color-black.mat-spinner circle {
  stroke: #000 !important;
}
.fv__ui-grouplist.horizontal .fv__ui-grouplist-item:nth-child(2)::after {
  content: none;
}
.fv__ui-print-layer .fv__ui-layer-header-title {
  font-size: 20px;
}
.fv__ui-print-layer .fv__ui-layer-header-button {
  display: flex;
}
.fv__ui-mobile .fv__ui-sidebar-nav-ctrl:nth-child(2),
.fv__ui-mobile .fv__ui-sidebar-nav-ctrl:nth-child(3),
.fv__ui-mobile .fv__ui-sidebar-nav-ctrl:nth-child(4) {
  display: none;
  visibility: hidden;
}
.postion-absolute {
  position: absolute;
}
.bg-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.bg-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.nav-spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 48%;
}

.mat-toolbar.mat-primary {
  background-color: #08a1bf !important;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

mat-dialog-container {
  border-radius: 0px !important;
  padding-top: 0 !important;
}

.fv__ui-nav-logo .fv__icon-logo {
  margin-left: 5px !important;
}

.fv__ui-mobile .fv__ui-mobile-header .fv__ui-button.active {
  background-color: #2ca3c4 !important;
}
.fv__ui-mobile .fv__ui-mobile-header {
  background-color: #2ca3c4 !important;
}
.fv__ui-mobile
  .fv__ui-mobile-header
  .fv__ui-mobile-topbar-tools-dropdown
  .fv__ui-dropdown-caret {
  display: none;
}
.mat-dialog-container {
  font-size: 16px;
}

.hide-toolbar .fv__ui-sidebar .fv__ui-sidebar-nav a:nth-child(1),
.hide-toolbar .fv__ui-sidebar .fv__ui-sidebar-nav a:nth-child(2),
.hide-toolbar .fv__ui-mobile-header-right a:nth-child(3),
.hide,
.mobile-toolbar-show {
  display: none !important;
}

@media (max-width: 767px) {
  .branding-caption {
    display: none;
  }
}
@media (max-width: 567px) {
  button,
  .primary-btn,
  .mat-dialog-container {
    font-size: 12px !important;
  }
  .adSnippetimg iframe {
    width: 100%;
    max-height: 200px;
  }
  .mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    line-height: 32px !important;
  }
  .gdpr-consent {
    flex-direction: column !important;
    /* justify-content: start; */
    align-items: flex-start !important;
    margin-bottom: 10px;
  }
  .ad-container {
    padding: 15px !important;
    align-items: center !important;
    margin-bottom: 0px !important;
  }
  .adSnippetimg img {
    max-height: 170px !important;
  }
  .adpanel-sheet {
    padding: 8px !important;
  }
  .ads-close {
    width: 89% !important;
  }
  .ad-container .ads-close {
    bottom: 6px !important;
    width: 98% !important;
  }
  .ads-close p {
    width: 70%;
    font-size: 12px;
  }
  .contentGDPR {
    width: 98% !important;
  }
  .contentGDPR p {
    font-size: 12px;
  }
  .mobile-toolbar-show {
    display: flex !important;
    padding-left: 15px !important;
    border-top: 1px solid #ccc;
  }
  .hide-mobile {
    display: none;
  }
  .logo_icon {
    width: auto;
    max-height: 50px;
    max-width: 170px;
  }
  .powered_by_iwrapper {
    width: 130px;
  }
  .discount-coupon-section {
    display: inline-grid !important;
  }
  .discount-coupon-section .border-right {
    display: none !important;
  }
  .discount-coupon-section .example-half-width,
  .discount-coupon-section button {
    margin-left: 0px !important;
  }
  .col-6 {
    width: 48%;
  }
  .col-4 {
    width: 32% !important;
  }
  .preview-fontweight {
    width: 90px !important;
    min-width: 90px !important;
  }
  .preview-section {
    width: 100% !important;
  }
  .downloadPdf-form .mat-form-field {
    width: 95% !important;
  }
  .downloadPdf-form .orderNoField {
    margin-left: 0px !important;
  }

  html {
    font-size: 50px;
  }
  .payment-reCaptcha {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  .payment-img {
    width: 94px !important;
    right: 0px !important;
    top: 48px !important;
  }
  .cardwidth {
    max-height: 25px !important;
    top: 41px !important;
    width: auto !important;
  }
  .mobileHeightConatiner {
    height: 81% !important;
  }
  .section {
    display: flex !important;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
  }
  .captcha-container {
    padding: 29px 0px 10px !important;
  }
}
